import React from "react"
import { graphql, Link, navigate } from "gatsby"
import Layout from "../components/layout/layout"
import InnerWrapper from "../components/layout/innerWrapper"
import { Row, Col, Carousel, Button } from "react-bootstrap"
import Breadcrumb from "react-bootstrap/Breadcrumb"
import SEO from "../components/seo"

const PortfolioDetail = ({ data }) => {
  return (
    <Layout className="main inner-page-with-banner">
      {/* TODO: Confirm update to pull from the WC Portfolio Object SEP Title Field Works */}
      {/* TODO: Confirm update to pull from the WC Portfolio Object SEP Description Field Works */}
      <SEO
        title={data.portfolio.seoTitle || `Walpole Outdoors`}
        description={
          data.portfolio.seoDescription ||
          `The standard all other fence and outdoor structures are measured by.`
        }
      />
      <InnerWrapper>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/custom-projects">Custom Projects</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/custom-projects/${data.portfolio.categorySlug}`}>
              {data.portfolio.category}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{data.portfolio.title}</Breadcrumb.Item>
        </Breadcrumb>

        <Row className="portfoliocontent">
          <Col md={8} className="item prodleft">
            <Carousel controls={data.portfolio.imagePaths.length > 1}>
              {data.portfolio.imagePaths.map((image) => (
                <Carousel.Item
                  key={image}
                  style={{ backgroundImage: `url(${image})` }}
                  className="project-slide"
                />
              ))}
            </Carousel>
          </Col>
          <Col md={4} className="prodright">
            <div className="summary entry-summary">
              <h1 className="portfolio-title">{data.portfolio.title}</h1>
              <p className="portfoliometa">{data.portfolio.subTitle}</p>
              <div
                className="product-details__short-description"
                dangerouslySetInnerHTML={{
                  __html: `<div> ${data.portfolio.content} </div>`,
                }}
              ></div>

              <Button
                className="portfolio-cta"
                onClick={() => navigate("/connect/schedule-consultation")}
              >
                Schedule a Consultation
              </Button>
            </div>
          </Col>
        </Row>
      </InnerWrapper>
    </Layout>
  )
}

export default PortfolioDetail

export const query = graphql`
  query($id: String!) {
    portfolio(id: { eq: $id }) {
      title
      content
      category
      categorySlug
      seoTitle
      seoDescription
      imagePaths
    }
  }
`
